import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import { purple } from "@mui/material/colors";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import styled from "styled-components";
import ButtonComponent from "../../../.reUseableComponents/Buttons/ButtonComponent";
import ModalButtonComponent from "../../../.reUseableComponents/Buttons/ModalButtonComponent";
import ModalComponent from "../../../.reUseableComponents/Modal/ModalComponent";
import SelectComponent from "../../../.reUseableComponents/TextField/SelectComponent";
import {
  CheckUniqueProductStockSku,
  CreateProduct,
  GetAllProductCategoryLookup,
  GetStoresForSelection,
  GetUniqueAutogeneratedSku,
} from "../../../api/AxiosInterceptors";
import { getAllStationLookupFunc } from "../../../apiCallingFunction";
import { styleSheet } from "../../../assets/styles/style";
import { EnumOptions } from "../../../utilities/enum";
import {
  CustomColorLabelledOutline,
  GridContainer,
  GridItem,
  placeholders,
} from "../../../utilities/helpers/Helpers";
import DragDropFile from "../../dragAndDrop/featureImage";
import {
  errorNotification,
  successNotification,
} from "../../../utilities/toast";
import UtilityClass from "../../../utilities/UtilityClass";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#000",
    color: "#fff",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({}));

const UploadProductModal = (props) => {
  const { open, onClose, rowData } = props;
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
    watch,
    control,
    unregister,
  } = useForm({
    defaultValues: {
      productName: rowData.title || "Default Product Name",
      description: rowData.description || "Default Description",
      weight: rowData.weight || "",
      price: rowData.price || "",
      purchasePrice: rowData.purchasePrice || "",
    },
  });
  useWatch({ name: "store", control });
  useWatch({ name: "productCategoryId", control });
  const handleFocus = (event) => event.target.select();
  const [storeList, setStoreList] = useState([]);
  const [productImageFile, setProductImageFile] = useState();
  const [isLowerPrice, setIsLowerPrice] = useState(false);
  const [productCategories, setProductCategories] = useState([]);
  const [trackInventoryCheckbox, setTrackInventoryCheckbox] = useState(true);
  const [productStations, setProductStations] = useState([]);
  const [stationValues, setStationValues] = useState([]);
  const [qtySelectedStation, setQtySelectedStation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getStoresForSelection = async () => {
    const res = await GetStoresForSelection();
    if (res.data.result != null) {
      setStoreList(res?.data?.result);
    }
  };

  let getAllStationLookup = async () => {
    let data = await getAllStationLookupFunc();
    if (data.length > 0) {
      if (data?.find((x) => x.productStationId == 0)) {
        data.shift();
      }
      setProductStations(data);
    }
  };

  const getAllProductCategoryLookup = async () => {
    const res = await GetAllProductCategoryLookup();
    if (res.data.result !== null) {
      setProductCategories(res.data.result);
    }
  };

  let getUniqueAutogeneratedSku = async () => {
    setValue("SKU", "loading...");
    let res = await GetUniqueAutogeneratedSku();
    if (res.data.isSuccess) {
      setValue("SKU", res.data.result.sku);
      unregister("SKU");
    } else {
      setValue("SKU", "");
    }
  };

  const updateProductStationQty = () => {
    const updatedProductStations = productStations?.map((item) => {
      return {
        ...item,
        quantityAvailable: 0,
        lowQuantityLimit: 0,
      };
    });
    setStationValues(updatedProductStations);
  };

  const handleUploadProduct = async (data) => {
    console.log(data);
    let res = await CheckUniqueProductStockSku(data.SKU);
    if (res.data.result.isExist) {
      errorNotification(
        LanguageReducer?.languageType?.SKU_NOT_AVAILBE_EMPTY_TOAST
      );
      return;
    }

    let productStocks = [];
    let count = 0;

    for (let index = 0; index < stationValues.length; index++) {
      if (stationValues[index]) {
        productStocks[count] = {
          Sku: data.SKU,
          price: data.price,
          QuantityAvailable: stationValues[index].quantityAvailable,
          LowQuantityLimit: stationValues[index].lowQuantityLimit,
          ProductStationId: stationValues[index].productStationId,
          VarientOption: "",
        };
        count++;
      }
    }
    const params = {
      sku: data.SKU,
      productName: data.productName,
      price: data.price,
      purchasePrice: data.purchasePrice,
      description: data.description,
      productCategoryId: data.productCategoryId.productCategoryId,
      featureImage: productImageFile,
      weight: data.weight,
      haveOptions: false,
      storeId: data.store.storeId,
      trackInventory: trackInventoryCheckbox,
      productOptions: [],
      productStocks: productStocks,
    };
    console.log("params", params);
    CreateProduct(params)
      .then((res) => {
        console.log("res:::", res);
        if (!res?.data?.isSuccess) {
          UtilityClass.showErrorNotificationWithDictionary(res?.data?.errors);
          setIsLoading(false);
        } else {
          successNotification(
            LanguageReducer?.languageType?.PRODUCT_CREATED_SUCCESSFULLY_TOAST
          );
          setIsLoading(false);
          navigate("/products");
        }
      })
      .catch((e) => {
        console.log("e", e);
        UtilityClass.showErrorNotificationWithDictionary(
          e?.response?.data?.errors
        );
        setIsLoading(false);
      })
      .finally((e) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    let sp = getValues("price");
    let pp = getValues("purchasePrice");
    if (parseInt(sp) && parseInt(pp)) {
      if (parseInt(sp) < parseInt(pp)) {
        setIsLowerPrice(true);
      } else {
        setIsLowerPrice(false);
      }
    } else {
      setIsLowerPrice(false);
    }
  }, [watch("purchasePrice"), watch("price")]);

  useEffect(() => {
    const selectedStore = storeList.find(
      (store) => store.storeId === rowData.storeId
    );
    setValue("store", selectedStore || null);
  }, [rowData, storeList]);

  useEffect(() => {
    const selectedCategory = productCategories.find(
      (category) => category?.productCategoryId === rowData?.productCategoryId
    );
    setValue("productCategoryId", selectedCategory || null);
  }, [rowData, productCategories]);

  useEffect(() => {
    setProductImageFile(rowData?.featureImage);
  }, [rowData]);

  useEffect(() => {
    if (productStations.length > 0) {
      setQtySelectedStation(productStations[0]);
      updateProductStationQty();
    }
  }, [productStations]);

  useEffect(() => {
    const defaultStation = productStations.find((station) =>
      station.sname.includes("Default")
    );
    if (defaultStation) {
      const defaultStationId = defaultStation.productStationId;
      setStationValues((prevValues) =>
        prevValues.map((station) =>
          station.productStationId === defaultStationId
            ? { ...station, quantityAvailable: rowData.quantityAvailable }
            : station
        )
      );
    }
  }, [productStations, rowData.quantityAvailable, setStationValues]);

  useEffect(() => {
    getStoresForSelection();
    getAllStationLookup();
    getAllProductCategoryLookup();
  }, []);

  return (
    <ModalComponent
      open={open}
      onClose={onClose}
      maxWidth="lg"
      title={"Upload Product"}
      actionBtn={
        <ModalButtonComponent
          title={"Create Product"}
          bg={purple}
          type="submit"
          loading={isLoading}
          onClick={handleSubmit(handleUploadProduct)}
        />
      }
    >
      <form onSubmit={handleSubmit(handleUploadProduct)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomColorLabelledOutline
              label={LanguageReducer?.languageType?.PRODUCT_DETAIL_TEXT}
            >
              <GridContainer>
                <Grid item xs={12} sm={6}>
                  <InputLabel required sx={styleSheet.inputLabelAddProduct}>
                    {LanguageReducer?.languageType?.PRODUCTS_STORE}
                  </InputLabel>
                  <SelectComponent
                    name="store"
                    optionProperty={"storeName"}
                    setValue={setValue}
                    control={control}
                    options={storeList}
                    isRHF={true}
                    required={true}
                    optionLabel={EnumOptions.STORE.LABEL}
                    optionValue={EnumOptions.STORE.VALUE}
                    {...register("store", {
                      required: {
                        value: true,
                      },
                    })}
                    value={getValues("store")}
                    onChange={(event, newValue) => {
                      const resolvedId = newValue ? newValue : null;
                      setValue("store", resolvedId);
                    }}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel required sx={styleSheet.inputLabelAddProduct}>
                    {LanguageReducer?.languageType?.TITLE_TEXT}
                  </InputLabel>
                  <TextField
                    type="text"
                    size="small"
                    id="productName"
                    name="productName"
                    onFocus={handleFocus}
                    fullWidth
                    variant="outlined"
                    {...register("productName", {
                      required: {
                        value: true,
                        message:
                          LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                      },
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message:
                          LanguageReducer?.languageType
                            ?.INPUT_SHOULD_NOT_BE_ONLY_SPACES,
                      },
                    })}
                    error={Boolean(errors.productName)} // set error prop
                    helperText={errors.productName?.message}
                    placeholder={placeholders.category_name}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <InputLabel required sx={styleSheet.inputLabelAddProduct}>
                    {LanguageReducer?.languageType?.DESCRIPTION_TEXT}
                  </InputLabel>
                  <TextField
                    placeholder="Description"
                    onFocus={handleFocus}
                    size="small"
                    multiline
                    fullWidth
                    rows={3}
                    variant="outlined"
                    id="description"
                    name="description"
                    {...register("description", {
                      required: {
                        value: true,
                        message:
                          LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                      },
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message:
                          LanguageReducer?.languageType
                            ?.INPUT_SHOULD_NOT_BE_ONLY_SPACES,
                      },
                    })}
                    error={Boolean(errors.description)} // set error prop
                    helperText={errors.description?.message}
                  />
                </Grid>
                <Grid item md={6} xs={12} sm={6}>
                  <InputLabel required sx={styleSheet.inputLabelAddProduct}>
                    {LanguageReducer?.languageType?.CATEGORY_TEXT}
                  </InputLabel>
                  <Box className={"flex_between"}>
                    <Box flexBasis={"100%"}>
                      <SelectComponent
                        name="productCategoryId"
                        control={control}
                        options={productCategories}
                        getOptionLabel={(option) => option?.categoryName}
                        isRHF={true}
                        required={true}
                        optionLabel={EnumOptions.CATAGORY.LABEL}
                        optionValue={EnumOptions.CATAGORY.VALUE}
                        {...register("productCategoryId", {
                          required: {
                            value: true,
                          },
                        })}
                        value={getValues("productCategoryId")}
                        onChange={(event, newValue) => {
                          const _productCategoryId = newValue ? newValue : null;
                          setValue("productCategoryId", _productCategoryId);
                        }}
                        errors={errors}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12} sm={6}>
                  <InputLabel required sx={styleSheet.inputLabelAddProduct}>
                    {LanguageReducer?.languageType?.WEIGHT_TEXT}
                  </InputLabel>
                  <TextField
                    placeholder={placeholders.weight}
                    onFocus={handleFocus}
                    type="number"
                    size="small"
                    id="weight"
                    name="weight"
                    inputProps={{
                      step: "any",
                    }}
                    fullWidth
                    variant="outlined"
                    {...register("weight", {
                      required: {
                        value: true,
                        message:
                          LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                      },
                      // pattern: {
                      //   value: /^[0-9]\d*$/,
                      //   message:
                      //     LanguageReducer?.languageType
                      //       ?.WEIGHT_MUST_BE_GREATER_THAN_ZERO_TOAST,
                      // },
                    })}
                    error={Boolean(errors.weight)} // set error prop
                    helperText={errors.weight?.message}
                  ></TextField>
                </Grid>
                <Grid item md={6} xs={12} sm={6}>
                  <InputLabel required sx={styleSheet.inputLabelAddProduct}>
                    {LanguageReducer?.languageType?.SALE_PRICE_TEXT}
                  </InputLabel>
                  <TextField
                    placeholder={placeholders.price}
                    type="number"
                    onFocus={handleFocus}
                    size="small"
                    id="price"
                    inputProps={{
                      step: "any",
                    }}
                    name="price"
                    fullWidth
                    variant="outlined"
                    {...register("price", {
                      required: {
                        value: true,
                        message:
                          LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                      },
                      pattern: {
                        // value: /^[1-9]\d*$/,
                        message:
                          LanguageReducer?.languageType
                            ?.PRICE_MUST_BE_GREATER_THAN_ZERO_TOAST,
                      },
                    })}
                    error={Boolean(errors.price)} // set error prop
                    helperText={errors.price?.message}
                  ></TextField>
                </Grid>
                <Grid item md={6} xs={12} sm={6}>
                  <InputLabel required sx={styleSheet.inputLabelAddProduct}>
                    {LanguageReducer?.languageType?.PURCHASE_PRICE_TEXT}
                  </InputLabel>
                  <TextField
                    placeholder={placeholders.price}
                    type="number"
                    size="small"
                    onFocus={handleFocus}
                    id="purchasePrice"
                    name="purchasePrice"
                    inputProps={{
                      step: "any",
                    }}
                    fullWidth
                    variant="outlined"
                    {...register("purchasePrice", {
                      required: {
                        value: true,
                        message:
                          LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                      },
                      pattern: {
                        // value: /^[1-9]\d*$/,
                        message:
                          LanguageReducer?.languageType
                            ?.PURCHASE_PRICE_MUST_BE_GREATER_THAN_ZERO_TOAST,
                      },
                    })}
                    min={0}
                    error={Boolean(errors.purchasePrice)} // set error prop
                    helperText={errors.purchasePrice?.message}
                  ></TextField>
                  {isLowerPrice && (
                    <FormLabel sx={{ fontSize: "10px" }} error>
                      *Your Purchase price is greater than sale price
                    </FormLabel>
                  )}
                </Grid>
              </GridContainer>
            </CustomColorLabelledOutline>
          </GridItem>
          {/* feature img box */}
          <GridItem xs={12} sm={12} md={6}>
            <CustomColorLabelledOutline label={"Feature Image"} height={"100%"}>
              <DragDropFile setProductFile={productImageFile} />
            </CustomColorLabelledOutline>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <CustomColorLabelledOutline
              label={LanguageReducer?.languageType?.INVENTORY_TEXT}
            >
              <GridContainer>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      onChange={(e) => {
                        setTrackInventoryCheckbox(!trackInventoryCheckbox);
                      }}
                      value={trackInventoryCheckbox}
                      control={
                        <Checkbox
                          sx={{
                            color: "#563AD5",
                            "&.Mui-checked": {
                              color: "#563AD5",
                            },
                          }}
                          checked={trackInventoryCheckbox}
                        />
                      }
                      label={
                        LanguageReducer?.languageType?.TRACK_INVENTORY_TEXT
                      }
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    alignContent={"center"}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"end"}
                  >
                    <InputLabel required sx={styleSheet.inputLabelAddProduct}>
                      {LanguageReducer?.languageType?.SKU_TEXT}
                    </InputLabel>
                    <Box mb={0.5}>
                      <ButtonComponent
                        title={
                          LanguageReducer?.languageType
                            ?.PRODUCTS_AUTO_GENERATE_SKU
                        }
                        onClick={getUniqueAutogeneratedSku}
                      />
                    </Box>
                  </Box>
                  <TextField
                    placeholder={placeholders.sku}
                    size="small"
                    onFocus={handleFocus}
                    id="SKU"
                    name="SKU"
                    fullWidth
                    variant="outlined"
                    {...register("SKU", {
                      required: {
                        value: true,
                        message:
                          LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                      },
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message:
                          LanguageReducer?.languageType
                            ?.INPUT_SHOULD_NOT_BE_ONLY_SPACES,
                      },
                    })}
                    error={Boolean(errors.SKU)} // set error prop
                    helperText={errors.SKU?.message}
                  ></TextField>
                </Grid>
                {trackInventoryCheckbox ? (
                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table fullWidth aria-label="customized table">
                        <TableBody>
                          {stationValues.map((row, i) => {
                            return (
                              <>
                                {row.productStationId > 0 && (
                                  <StyledTableRow key={row.productStationId}>
                                    <StyledTableCell
                                      align="center"
                                      component="th"
                                      scope="row"
                                    >
                                      {row.sname}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      <Box display={"flex"} gap={1}>
                                        <TextField
                                          onChange={(e) => {
                                            let newArr = [...stationValues];
                                            newArr[i] = {
                                              sname: newArr[i].sname,
                                              productStationId:
                                                row.productStationId,
                                              quantityAvailable:
                                                parseInt(e.target.value) || 0,
                                              lowQuantityLimit: newArr[i]
                                                ?.lowQuantityLimit
                                                ? newArr[i].lowQuantityLimit
                                                : 0,
                                            };
                                            setStationValues(newArr);
                                          }}
                                          defaulValue={
                                            row.quantityAvailable || 0
                                          }
                                          value={row.quantityAvailable || 0}
                                          min={0}
                                          type="number"
                                          size="small"
                                          onFocus={handleFocus}
                                          fullWidth
                                          variant="outlined"
                                          label="Quantity"
                                        ></TextField>
                                        <TextField
                                          onChange={(e) => {
                                            let newArr = [...stationValues];
                                            newArr[i] = {
                                              sname: newArr[i].sname,
                                              productStationId:
                                                row.productStationId,
                                              lowQuantityLimit:
                                                parseInt(e.target.value) || 0,
                                              quantityAvailable: newArr[i]
                                                ?.quantityAvailable
                                                ? newArr[i].quantityAvailable
                                                : 0,
                                            };
                                            setStationValues(newArr);
                                          }}
                                          defaulValue={
                                            row.lowQuantityLimit || 0
                                          }
                                          value={row.lowQuantityLimit || 0}
                                          min={0}
                                          type="number"
                                          size="small"
                                          onFocus={handleFocus}
                                          fullWidth
                                          variant="outlined"
                                          label="Low Quantity Amount"
                                        ></TextField>
                                      </Box>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                )}
                              </>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                ) : null}
              </GridContainer>
            </CustomColorLabelledOutline>
          </GridItem>
        </GridContainer>
      </form>
    </ModalComponent>
  );
};

export default UploadProductModal;

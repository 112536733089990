const Warning = "#ff9800  !important";
const Success = "#28a745  !important;";
const Danger = "#dc3545  !important;";
const LinkColor = "#0070E0 !important";

export { Warning, Success, Danger, LinkColor };

const Colors = {
  linkColor: "#0070E0 !important",
  danger: "#dc3545  !important",
  uploadFile: "#52A7E7  !important",
  succes: "#28a745  !important",
  pdf: "#C50606  !important",
  view: "#00A3DA  !important",
  image: "#43a047  !important",
  purple: "#563ad5  !important",
  primary:
    "radial-gradient(100% 5102.04% at 100% 100%, #563AD5 0%, #442CAC 100%)  !important",
  warning: "#ff9800  !important",
};
export default Colors;

import { setThisKeyCookie } from "../../../utilities/cookies";
import { EnumCookieKeys } from "../../../utilities/enum";

const setLoginCookies = (res) => {
  const data = res.data.result;
  setThisKeyCookie("access_token", res.data.result.access_token);
  setThisKeyCookie("refresh_token", res.data.result.refresh_token);
  setThisKeyCookie("user_name", res.data.result.user_name);
  setThisKeyCookie("clIdentifier", res.data.result.clientPrefix);
  setThisKeyCookie("id_token", res.data.result.id_token);
  setThisKeyCookie("patronTypeId", res?.data?.result?.userRoleId);
  setThisKeyCookie(EnumCookieKeys.COUNTRY_ID, data?.country?.countryId);
  setThisKeyCookie(EnumCookieKeys.COUNTRY_CODE, data?.country?.mapCountryCode);
  setThisKeyCookie(
    "allowPersonalCarrierContract",
    res?.data?.result?.allowPersonalCarrierContract
  );
  setThisKeyCookie("client_code", res?.data?.result?.client_code);
  setThisKeyCookie(
    "expires_in",
    res.data.result.expires_in * 1000 + Date.now()
  );
  setThisKeyCookie("companyImage", res.data.result.companyImage);
  setThisKeyCookie("email", res.data.result.email);
  setThisKeyCookie(
    EnumCookieKeys.TIME_ZONE,
    data?.region ? data.region?.timeZone : ""
  );
  let country = "";
  if (data?.country) {
    country = JSON.stringify(data?.country);
    setThisKeyCookie(EnumCookieKeys.COUNTRY, country);
  }
  if (data?.restrictedCountry) {
    let restrictedCountry = JSON.stringify(data?.restrictedCountry);
    setThisKeyCookie(EnumCookieKeys.RESTRICTED_COUNTRY, restrictedCountry);
  }
};

export default setLoginCookies;

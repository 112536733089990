export const profileEnglish = {
  SETTING_PROFILE_TEXT: "Profile",
  SETTING_PROFILE_PERSONAL_INFORMATION: "Personal Information",
  SETTING_PROFILE_NAME: "Name",
  SETTING_PROFILE_COMPANY_NAME: "Company Name (Optional)",
  SETTING_PROFILE_MOBILE_NO: "Mobile No.",
  SETTING_PROFILE_PHONE_NO: "Phone No. (Optional)",
  SETTING_PROFILE_COUNTRY: "Country",
  SETTING_PROFILE_REGION_CITY: "Region/City",
  SETTING_PROFILE_CITY: "City",
  SETTING_PROFILE_APARTMENT_SUITE_ETC: "Apartment/Suite/Etc",
  SETTING_PROFILE_REGION_TIME_ZONE: "Region Time Zone",
  SETTING_PROFILE_UPDATE: "Update",
  SETTING_PROFILE_USERNAME: "Username",
  SETTING_PROFILE_EMAIL_ADDRESS: "Email Address",
  SETTING_LETS_GET_STARTED: "Let's get started",
  SETTING_LOG_IN: "Log In",
  SETTING_ALREADY_HAVE_AN_ACCOUNT: "Already have an account?",
  SETTING_SIGN_UP: "Sign Up",
};
export const profileArabic = {
  SETTING_PROFILE_TEXT: "الملف الشخصي",
  SETTING_PROFILE_PERSONAL_INFORMATION: "معلومات شخصية",
  SETTING_PROFILE_NAME: "الاسم",
  SETTING_PROFILE_COMPANY_NAME: "اسم الشركة (اختياري)",
  SETTING_PROFILE_MOBILE_NO: "رقم الجوال",
  SETTING_PROFILE_PHONE_NO: "رقم الهاتف (اختياري)",
  SETTING_PROFILE_COUNTRY: "الدولة",
  SETTING_PROFILE_REGION_CITY: "المنطقة/المدينة",
  SETTING_PROFILE_CITY: "مدينة",
  SETTING_PROFILE_APARTMENT_SUITE_ETC: "شقة/جناح/إلخ",
  SETTING_PROFILE_REGION_TIME_ZONE: "المنطقة الزمنية",
  SETTING_PROFILE_UPDATE: "تحديث",
  SETTING_PROFILE_USERNAME: "اسم المستخدم",
  SETTING_PROFILE_EMAIL_ADDRESS: "عنوان البريد الإلكتروني",
  SETTING_LETS_GET_STARTED: "هيا لنبدأ",
  SETTING_LOG_IN: "تسجيل الدخول",
  SETTING_ALREADY_HAVE_AN_ACCOUNT: "هل لديك حساب بالفعل؟",
  SETTING_SIGN_UP: "اشترك",
};
export const profileChines = {
  SETTING_PROFILE_TEXT: "个人资料",
  SETTING_PROFILE_PERSONAL_INFORMATION: "个人信息",
  SETTING_PROFILE_NAME: "名称",
  SETTING_PROFILE_COMPANY_NAME: "公司名称（可选）",
  SETTING_PROFILE_MOBILE_NO: "手机号码",
  SETTING_PROFILE_PHONE_NO: "电话号码(可选)",
  SETTING_PROFILE_COUNTRY: "国家",
  SETTING_PROFILE_REGION_CITY: "地区/城市",
  SETTING_PROFILE_CITY: "城市",
  SETTING_PROFILE_APARTMENT_SUITE_ETC: "公寓/套房/等",
  SETTING_PROFILE_REGION_TIME_ZONE: "区域时区",
  SETTING_PROFILE_UPDATE: "更新",
  SETTING_PROFILE_USERNAME: "用户名",
  SETTING_PROFILE_EMAIL_ADDRESS: "电子邮件地址",
  SETTING_LETS_GET_STARTED: "让我们开始吧",
  SETTING_LOG_IN: "登录",
  SETTING_ALREADY_HAVE_AN_ACCOUNT: "已经有一个账户了？",
  SETTING_SIGN_UP: "注册",
};
export const profileUrdu = {
  SETTING_PROFILE_TEXT: "پروفائل",
  SETTING_PROFILE_PERSONAL_INFORMATION: "ذاتی معلومات",
  SETTING_PROFILE_NAME: "نام",
  SETTING_PROFILE_COMPANY_NAME: "کمپنی کا نام (اختیاری)",
  SETTING_PROFILE_MOBILE_NO: "موبائل نمبر",
  SETTING_PROFILE_PHONE_NO: "فون نمبر (اختیاری)",
  SETTING_PROFILE_COUNTRY: "ملک",
  SETTING_PROFILE_REGION_CITY: "علاقہ/شہر",
  SETTING_PROFILE_CITY: "شہر",
  SETTING_PROFILE_APARTMENT_SUITE_ETC: "اپارٹمنٹ/سوئٹ/وغیرہ",
  SETTING_PROFILE_REGION_TIME_ZONE: "علاقہ ٹائم زون",
  SETTING_PROFILE_UPDATE: "اپ ڈیٹ کریں",
  SETTING_PROFILE_USERNAME: "یوزر نیم",
  SETTING_PROFILE_EMAIL_ADDRESS: "ای میل ایڈریس",
  SETTING_LETS_GET_STARTED: "چلیں شروع کریں",
  SETTING_LOG_IN: "لاگ ان",
  SETTING_ALREADY_HAVE_AN_ACCOUNT: "کیا آپ کے پاس پہلے سے ایک اکاؤنٹ موجود ہے؟",
  SETTING_SIGN_UP: "سائن اپ کریں",
};

export const UserEnglish = {
  SETTING_USER_TEXT: "Users",
  SETTING_USER_USERS: "User(s)",
  SETTING_USER_ADD_EMPLOYEE: "Add Employee",
  SETTING_USER_UPDATE_EMPLOYEE: "Update Employee",
  SETTING_USER_FILTERS: "Filters",
  SETTING_USER_USER_NAME: "User Name",
  SETTING_USER_NAME: "Name",
  SETTING_USER_PHONE: "Phone",
  SETTING_USER_EMAIL: "Email",
  SETTING_USER_GENDER: "Gender",
  SETTING_USER_EMPLOYEE_TYPE: "Employee Type",
  SETTING_USER_DOB: "DOB",
  SETTING_USER_STATUS: "Status",
  SETTING_USER_ACTION: "Action",
  SETTING_USER_START_DATE: "Start Date",
  SETTING_USER_END_DATE: "End Date",
  SETTING_USER_USER_ROLE: "User Role",
  SETTING_STORE: "Store",
  SETTING_LATITUDE_AND_LONGITUDE: "Latitude And Longitude",
  SETTING_GET_ADDRESS: "Get Address",
  SETTING_FORM_MAP: "Form Map",
  SETTING_USER_FILTER: "Filter",
  SETTING_USER_CLEAR_FILTER: "Clear Filter",
  SETTING_USER_FULL_NAME: "Full Name",
  SETTING_USER_CONFIRM_ACCOUNT: "Confirm Account",
  SETTING_USER_MOBILE: "Mobile",
  SETTING_USER_PHONE_NO: "Phone No",
  SETTING_USER_COUNTRY: "Country",
  SETTING_USER_REGION_CITY: "Region/City",
  SETTING_USER_AREA_NEIGHBOURHOOD: "Area/Neighbourhood",
  SETTING_USER_ADDRESS_LINE_1: "Address Line 1",
  SETTING_USER_ADDRESS_LINE_2: "Address Line 2",
  SETTING_USER_LOG_IN: "Log In",
  SETTING_USER_USERNAME: "Username",
  SETTING_USER_PASSWORD: "Password",
  SETTING_USER_CONFIRM_PASSWORD: "Confirm Password",
};
export const UserArabic = {
  SETTING_USER_TEXT: "المستخدمين",
  SETTING_USER_USERS: "مستخدم (جمع: مستخدمون)",
  SETTING_USER_ADD_EMPLOYEE: "إضافة موظف",
  SETTING_USER_FILTERS: "الفلتر",
  SETTING_USER_USER_NAME: "اسم المستخدم",
  SETTING_USER_NAME: "الاسم",
  SETTING_USER_PHONE: "هاتف",
  SETTING_USER_EMAIL: "البريد الإلكتروني",
  SETTING_USER_GENDER: "الجنس",
  SETTING_USER_EMPLOYEE_TYPE: "نوع الموظف",
  SETTING_USER_DOB: "تاريخ الميلاد",
  SETTING_USER_STATUS: "الحالة",
  SETTING_USER_ACTION: "إجراء",
  SETTING_USER_START_DATE: "Start Date",
  SETTING_USER_END_DATE: "End Date",
  SETTING_USER_USER_ROLE: "دور المستخدم",
  SETTING_USER_FILTER: "الفلتر",
  SETTING_USER_CLEAR_FILTER: "مسح الفلتر",
  SETTING_USER_FULL_NAME: "الاسم الكامل",
  SETTING_USER_CONFIRM_ACCOUNT: "تأكيد الحساب",
  SETTING_USER_MOBILE: "الهاتف المحمول",
  SETTING_USER_PHONE_NO: "رقم الهاتف",
  SETTING_USER_COUNTRY: "البلد",
  SETTING_USER_REGION_CITY: "المنطقة/المدينة",
  SETTING_USER_AREA_NEIGHBOURHOOD: "المنطقة / الحي",
  SETTING_USER_ADDRESS_LINE_1: "السطر الأول من العنوان",
  SETTING_USER_ADDRESS_LINE_2: "السطر الثاني من العنوان",
  SETTING_USER_LOG_IN: "تسجيل الدخول",
  SETTING_USER_USERNAME: "اسم المستخدم",
  SETTING_USER_PASSWORD: "كلمة المرور",
  SETTING_STORE: "المتجر",
  SETTING_LATITUDE_AND_LONGITUDE: "خطوط العرض والطول",
  SETTING_GET_ADDRESS: "احصل على العنوان",
  SETTING_FORM_MAP: "خريطة النموذج",
  SETTING_USER_CONFIRM_PASSWORD: "تأكيد كلمة المرور",
};
export const UserChines = {
  SETTING_USER_TEXT: "用户",
  SETTING_USER_USERS: "用户（们）",
  SETTING_USER_ADD_EMPLOYEE: "添加员工",
  SETTING_USER_FILTERS: "筛选",
  SETTING_USER_USER_NAME: "用户名",
  SETTING_USER_NAME: "名称",
  SETTING_USER_PHONE: "电话 ",
  SETTING_USER_EMAIL: "电子邮件",
  SETTING_USER_GENDER: "性别",
  SETTING_USER_EMPLOYEE_TYPE: "员工类型",
  SETTING_USER_DOB: "出生日期",
  SETTING_USER_STATUS: "状态",
  SETTING_USER_ACTION: "操作",
  SETTING_USER_START_DATE: "开始日期",
  SETTING_USER_END_DATE: "结束日期",
  SETTING_USER_USER_ROLE: "用户角色",
  SETTING_USER_FILTER: "筛选",
  SETTING_USER_CLEAR_FILTER: "清除筛选",
  SETTING_USER_FULL_NAME: "全名",
  SETTING_USER_CONFIRM_ACCOUNT: "确认账户",
  SETTING_USER_MOBILE: "手机号",
  SETTING_USER_PHONE_NO: "电话号码",
  SETTING_USER_COUNTRY: "国家",
  SETTING_USER_REGION_CITY: "地区/城市",
  SETTING_USER_AREA_NEIGHBOURHOOD: "区域/社区",
  SETTING_USER_ADDRESS_LINE_1: "地址行1",
  SETTING_USER_ADDRESS_LINE_2: "地址行2",
  SETTING_USER_LOG_IN: "登录",
  SETTING_USER_USERNAME: "用户名",
  SETTING_USER_PASSWORD: "密码",
  SETTING_STORE: "店铺",
  SETTING_LATITUDE_AND_LONGITUDE: "緯度和經度",
  SETTING_GET_ADDRESS: "取得地址",
  SETTING_FORM_MAP: "地图表单",
  SETTING_USER_CONFIRM_PASSWORD: "确认密码",
};
export const UserUrdu = {
  SETTING_USER_TEXT: "صارفین",
  SETTING_USER_USERS: "صارف(ین)",
  SETTING_USER_ADD_EMPLOYEE: "ملازم شامل کریں",
  SETTING_USER_UPDATE_EMPLOYEE: "ملازم کو اپ ڈیٹ کریں",
  SETTING_USER_FILTERS: "فلٹرز",
  SETTING_USER_USER_NAME: "صارف کا نام",
  SETTING_USER_NAME: "نام",
  SETTING_USER_PHONE: "فون",
  SETTING_USER_EMAIL: "ای میل",
  SETTING_USER_GENDER: "جنس",
  SETTING_USER_EMPLOYEE_TYPE: "ملازم کی قسم",
  SETTING_USER_DOB: "تاریخ پیدائش",
  SETTING_USER_STATUS: "حیثیت",
  SETTING_USER_ACTION: "عمل",
  SETTING_USER_START_DATE: "شروع ہونے کی تاریخ",
  SETTING_USER_END_DATE: "اختتام کی تاریخ",
  SETTING_USER_USER_ROLE: "صارف کا کردار",
  SETTING_STORE: "دکان",
  SETTING_LATITUDE_AND_LONGITUDE: "عرض بلد اور طول بلد",
  SETTING_GET_ADDRESS: "پتہ حاصل کریں",
  SETTING_FORM_MAP: "فارم کا نقشہ",
  SETTING_USER_FILTER: "فلٹر",
  SETTING_USER_CLEAR_FILTER: "فلٹر صاف کریں",
  SETTING_USER_FULL_NAME: "مکمل نام",
  SETTING_USER_CONFIRM_ACCOUNT: "اکاؤنٹ کی تصدیق کریں",
  SETTING_USER_MOBILE: "موبائل",
  SETTING_USER_PHONE_NO: "فون نمبر",
  SETTING_USER_COUNTRY: "ملک",
  SETTING_USER_REGION_CITY: "علاقہ/شہر",
  SETTING_USER_AREA_NEIGHBOURHOOD: "علاقہ/محلہ",
  SETTING_USER_ADDRESS_LINE_1: "پتہ لائن 1",
  SETTING_USER_ADDRESS_LINE_2: "پتہ لائن 2",
  SETTING_USER_LOG_IN: "لاگ ان کریں",
  SETTING_USER_USERNAME: "صارف کا نام",
  SETTING_USER_PASSWORD: "پاسورڈ",
  SETTING_USER_CONFIRM_PASSWORD: "پاس ورڈ کی تصدیق کریں۔",
};

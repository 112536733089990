import {
  Avatar,
  Box,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useSelector } from "react-redux";
import OrderDetailModal from "../../../.reUseableComponents/Modal/InfoModal";
import {
  GetOrderItemByOrderNo,
  GetShipmentInfoByOrderNo,
  RefreshCarrierStatus,
} from "../../../api/AxiosInterceptors";
import { styleSheet } from "../../../assets/styles/style";
import OrderItemDetailModal from "../../../components/modals/orderModals/OrderItemDetailModal";
// import OrderItemDetailModal from "../../../.reUseableComponents/Modal/InfoModal";
import DataGridProComponent from "../../../.reUseableComponents/DataGrid/DataGridProComponent";
import { OrderCardComponent } from "../../../.reUseableComponents/cardComponent/orderCardComponent";
import StatusBadge from "../../../components/shared/statudBadge";
import UtilityClass from "../../../utilities/UtilityClass";
import {
  EnumChangeFilterModelApiUrls,
  EnumDetailsModalType,
  EnumFullFillmentStatus,
  viewTypesEnum,
} from "../../../utilities/enum";
import Colors from "../../../utilities/helpers/Colors";
import {
  ActionButtonCustom,
  ClipboardIcon,
  CodeBox,
  DescriptionBoxWithChild,
  DialerBox,
  GridContainer,
  GridItem,
  amountFormat,
  checkRefArrayValue,
  fetchMethod,
  greyBorder,
  rightColumn,
  useClientSubscriptionReducer,
  useGetWindowHeight,
  usePagination,
} from "../../../utilities/helpers/Helpers";
import { PaginationComponent } from "../../../utilities/helpers/paginationSchema";

function OrderList(props, ref) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    allOrders,
    setAllOrders,
    getOrdersRef,
    resetRowRef,
    loading,
    allCarrierTrackingStatus,
    getAllCarrierTrackingStatusForSelection,
    setShowActionToolbarButtons,
    getAllOrders,
    allOrdersCount,
    isFilterOpen,
    viewMode,
    setAllOrderLoading,
  } = props;
  const clientSubscriptionData = useClientSubscriptionReducer();
  const { height: windowHeight } = useGetWindowHeight(clientSubscriptionData);
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const [orderIteminfoModal, setOrderIteminfoModal] = useState({
    open: false,
    loading: {},
    data: [],
  });
  const [infoModal, setInfoModal] = useState({
    open: false,
    loading: {},
    data: [],
  });
  const [orderNo, setOrderNo] = useState("");
  // const [isNoteAdded, setIsNoteAdded] = useState(false)
  const [isNoteAdded, setIsNoteAdded] = useState({
    loading: false,
    orderNo: "",
    isAdded: false,
  });

  const [refreshCarrierStatusLoading, setRefreshCarrierStatusLoading] =
    useState({});

  const handleGetShipmentInfoByOrderNo = async (orderNum) => {
    // let orderNum = orderNo; //if use action button from dropdown
    setInfoModal((prev) => ({ ...prev, loading: { [orderNum]: true } }));
    setIsNoteAdded((prev) => ({ ...prev, loading: { [orderNum]: true } }));
    await GetShipmentInfoByOrderNo(orderNum)
      .then((res) => {
        const response = res.data;
        setInfoModal((prev) => ({ ...prev, open: true, data: response }));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setInfoModal((prev) => ({ ...prev, loading: { [orderNum]: false } }));
        setIsNoteAdded((prev) => ({ ...prev, loading: { [orderNum]: false } }));
      });
  };
  useEffect(() => {
    if (isNoteAdded.orderNo && isNoteAdded.isAdded) {
      handleGetShipmentInfoByOrderNo(isNoteAdded.orderNo);
      setIsNoteAdded((prev) => ({ ...prev, isAdded: false }));
    }
  }, [isNoteAdded.isAdded]);
  const handleActionButton = (cTarget, orderData) => {
    setAnchorEl(cTarget);
    setOrderNo(orderData.OrderNo);
  };
  const handleGetShipmentItemInfoByOrderNo = async (orderNum) => {
    setOrderIteminfoModal((prev) => ({
      ...prev,
      loading: { [orderNum]: true },
    }));

    await GetOrderItemByOrderNo(orderNum)
      .then((res) => {
        const response = res.data;
        setOrderIteminfoModal((prev) => ({
          ...prev,
          open: true,
          data: response,
        }));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setOrderIteminfoModal((prev) => ({
          ...prev,
          loading: { [orderNum]: false },
        }));
      });
  };

  const getCarrierText = (orderNo) => {
    let str = "Unassigned";
    // Check if any object has carrier
    const order = allOrders.find((x) => x.OrderNo == orderNo);
    if (order?.CarrierId) {
      str = order?.CarrierName || "";
    }
    return str;
  };

  const handleRefresh = (rowArray) => {
    console.log("first");
    (rowArray || getOrdersRef?.current).forEach(async (ordNo) => {
      const selectedOrder = allOrders.find((ord) => ord.OrderNo === ordNo);
      const hasRefreshBtn =
        !selectedOrder?.IsClientCarrier && selectedOrder?.CarrierId != null;
      if (hasRefreshBtn) {
        const { response } = await fetchMethod(
          () => RefreshCarrierStatus(selectedOrder.OrderId),
          setRefreshCarrierStatusLoading,
          true,
          selectedOrder.OrderId
        );
        if (response && response.result && response.result.data) {
          const updatedStatus = response.result.data;
          setAllOrders((prev) => {
            const _orders = [...prev];
            const _selectedOrderIndex = _orders.findIndex(
              (ord) => ord.OrderId == selectedOrder.OrderId
            );
            _orders[_selectedOrderIndex].TrackingStatus =
              updatedStatus.trackingStatus;
            _orders[_selectedOrderIndex].CarrierLastUpdateDateTime =
              updatedStatus.carrierLastUpdateDateTime;
            return _orders;
          });
          const isStatusExist = allCarrierTrackingStatus.some(
            (dt) =>
              dt.carrierTrackingStatusId === updatedStatus.trackingStatusId
          );

          if (!isStatusExist) {
            getAllCarrierTrackingStatusForSelection();
          }
        }
      }
    });
  };
  // for call method in parent
  useImperativeHandle(ref, () => ({
    Refresh() {
      handleRefresh();
    },
  }));
  const columns = [
    {
      field: "OrderNo",
      headerName: (
        <Stack direction={"column"}>
          <Box sx={{ fontWeight: "bold" }}>
            {LanguageReducer?.languageType?.ORDERS_ORDER_NO_REF_NO}
          </Box>
        </Stack>
      ),
      minWidth: 120,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack flexDirection={"row"} alignItems={"center"}>
            <Stack sx={{ textAlign: "" }} direction={"column"}>
              <CodeBox
                title={
                  infoModal.loading[params.row.OrderNo]
                    ? "loading..."
                    : params.row.OrderNo
                }
                onClick={(e) => {
                  handleGetShipmentInfoByOrderNo(params.row.OrderNo);
                  setIsNoteAdded((prev) => ({
                    ...prev,
                    orderNo: params.row.OrderNo,
                  }));
                }}
                copyBtn
                sx={{
                  textDecoration: infoModal.loading[params.row.OrderNo]
                    ? "none"
                    : "underline",
                }}
              />
              {params?.row?.RefNo && (
                <CodeBox title={params?.row?.RefNo} color={Colors.purple} />
              )}
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "Carrier",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.SHIPMENTS_CARRIER_NAME}
        </Box>
      ),
      minWidth: 180,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {params.row?.CarrierImage && (
              <Avatar
                variant="square"
                src={params.row?.CarrierImage}
                alt={params.row?.CarrierName}
                sx={{ width: 30, height: 30 }}
              />
            )}
            <Box>
              <Box
                sx={{
                  color: `${!params.row?.CarrierId ? Colors.danger : ""}`,
                  fontSize: "10px",
                }}
              >
                {getCarrierText(params?.row?.OrderNo)}
              </Box>
              <Typography variant="caption" color="textSecondary">
                {params.row?.CarrierTrackingNo}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "Store",
      // headerAlign: "center",
      // align: "center",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {LanguageReducer?.languageType?.ORDERS_STORE_INFO}
        </Box>
      ),
      minWidth: 130,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box disableRipple>
            <>
              <Box>{params.row.StoreName}</Box>
              <Box>
                <DialerBox phone={params.row.Mobile1} />
              </Box>
              <Box sx={{ fontSize: "10px" }}>{params.row?.SaleChannelName}</Box>
            </>
          </Box>
        );
      },
    },
    {
      field: "DropOfAddress",
      // headerAlign: "center",
      // align: "center",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {LanguageReducer?.languageType?.ORDERS_CUSTOMER_INFO}
        </Box>
      ),
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box display={"flex"} flexDirection={"column"} disableRipple>
            <>
              <Box sx={{ fontWeight: "bold" }}>
                {params.row.CustomerName}
                <DescriptionBoxWithChild>
                  <TableContainer>
                    <Table sx={{ minWidth: 275 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              fontSize: "11px",
                              padding: "5px",
                              // width: "110px",
                            }}
                            align="left"
                          >
                            Name
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              fontSize: "11px",
                              padding: "5px",
                            }}
                          >
                            Mobile
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              fontSize: "11px",
                              padding: "5px",
                              // width: "150px",
                            }}
                          >
                            Address
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          key={params.row.CustomerName}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            sx={{
                              padding: "7px",
                              fontSize: "11px",
                            }}
                            align="left"
                          >
                            {params.row.CustomerName}
                          </TableCell>
                          <TableCell
                            sx={{ padding: "7px", fontSize: "11px" }}
                            align="right"
                          >
                            <DialerBox phone={params.row.Mobile1} />
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "7px",
                              fontSize: "11px",
                              // width: "150px",
                            }}
                          >
                            {params.row.CustomerFullAddress}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </DescriptionBoxWithChild>
              </Box>
            </>
          </Box>
        );
      },
    },
    {
      field: "Payment",
      headerAlign: "center",
      align: "center",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {LanguageReducer?.languageType?.ORDERS_PAYMENT_STATUS}
        </Box>
      ),
      minWidth: 120,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            sx={{ textAlign: "center" }}
            disableRipple
          >
            <>
              <Box sx={{ fontWeight: "bold" }}>{params.row.PaymentMethod}</Box>
              <StatusBadge
                title={params.row.PaymentStatus}
                color={
                  params.row.PaymentStatus === "Unpaid" ? "#fff;" : "#fff;"
                }
                bgColor={
                  params.row.PaymentStatus === "Unpaid"
                    ? "#dc3545;"
                    : "#28a745;"
                }
              />
            </>
          </Box>
        );
      },
    },
    {
      field: "FulfillmentStatus",
      headerAlign: "center",
      align: "center",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {LanguageReducer?.languageType?.ORDERS_FULFILLMENT_STATUS}
        </Box>
      ),
      minWidth: 120,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            sx={{ textAlign: "center" }}
            disableRipple
          >
            <>
              {params.row.OrderTypeName != "Regular" && (
                <StatusBadge
                  title={params.row.FullFillmentStatus}
                  color={"#fff;"}
                  bgColor={
                    params.row?.FullFillmentStatusId ==
                    EnumFullFillmentStatus.UnFulfillment
                      ? "#dc3545;"
                      : "#28a745;"
                  }
                />
              )}
            </>
            <Box>
              {UtilityClass.convertUtcToLocalAndGetDate(
                params.row?.FulFilledDate
              )}
            </Box>
          </Box>
        );
      },
    },
    {
      field: "OrderTypeName",
      headerAlign: "center",
      align: "center",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.ORDERS_ORDER_TYPE}
        </Box>
      ),
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction={"column"}>
            <StatusBadge
              title={params.row.OrderTypeName}
              color="#1E1E1E;"
              bgColor="#EAEAEA"
            />
            <Box>
              {UtilityClass.convertUtcToLocalAndGetDate(params.row.OrderDate)}
            </Box>
          </Stack>
        );
      },
    },
    {
      field: "TrackingStatus",
      headerAlign: "center",
      align: "center",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {LanguageReducer?.languageType?.ORDERS_TRACKING_STATUS}
        </Box>
      ),
      minWidth: 200,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box className={"flex_center"} py={1}>
            <Box display="flex" flexDirection="column">
              <StatusBadge
                title={row.TrackingStatus}
                color="#1E1E1E;"
                bgColor="#EAEAEA"
                showBtn={!row?.IsClientCarrier && row?.CarrierId != null}
                btnLoading={refreshCarrierStatusLoading[row.OrderId]}
                btnOnClick={() => handleRefresh([row.OrderNo])}
              />
              {row?.CarrierLastUpdateDateTime && (
                <Box sx={{ textAlign: "center", display: "inline" }}>
                  <Typography fontSize="10px">
                    {UtilityClass.convertUtcToLocalAndGetTime(
                      row?.CarrierLastUpdateDateTime
                    )}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        );
      },
    },
    {
      field: "ItemsCount",
      headerAlign: "center",
      align: "center",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.ORDERS_ITEM_COUNT}
        </Box>
      ),
      minWidth: 90,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Box className={"flex_center"} flexDirection={"column"}>
              {orderIteminfoModal?.loading[params.row.OrderNo] ? (
                <CircularProgress size={20} />
              ) : (
                <CodeBox
                  title={params.row.ItemsCount}
                  onClick={(e) => {
                    handleGetShipmentItemInfoByOrderNo(params.row.OrderNo);
                  }}
                  eyeBtn={true}
                />
              )}
            </Box>
          </>
        );
      },
    },
    {
      field: "Amount",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.ORDERS_AMOUNT}
        </Box>
      ),
      minWidth: 120,
      flex: 1,
      ...rightColumn,
      renderCell: (params) => {
        return <Box>{amountFormat(params.row.Amount)}</Box>;
      },
    },
    // {
    //   field: "Action",
    //   hide: true,
    //   headerName: (
    //     <Box sx={{ fontWeight: "600" }}>
    //       {" "}
    //       {LanguageReducer?.languageType?.ACTION}
    //     </Box>
    //   ),
    //   renderCell: (params) => {
    //     return (
    //       <Box>
    //         <IconButton
    //           onClick={(e) => handleActionButton(e.currentTarget, params.row)}
    //         >
    //           <MoreVertIcon />
    //         </IconButton>
    //       </Box>
    //     );
    //   },
    //   minWidth: 60,
    //   flex: 1,
    // },
  ];

  const [selectionModel, setSelectionModel] = useState([]);
  const { currentPage, pageSize, handlePageChange, handlePageSizeChange } =
    usePagination(0, 10);
  const handleSelectedRow = (oNos) => {
    getOrdersRef.current = oNos;
    setSelectionModel(oNos);
    props.setSelectionModel(oNos);
    setShowActionToolbarButtons(checkRefArrayValue(oNos));
  };
  ////////////
  useEffect(() => {
    if (resetRowRef && resetRowRef.current) {
      getOrdersRef.current = [];
      resetRowRef.current = false;
      setSelectionModel([]);
    }
  }, [resetRowRef.current]);
  const calculatedHeight = isFilterOpen
    ? windowHeight - 95 - 59 - 62.5 - 210
    : windowHeight - 95 - 59 - 63;
  const calculatedGridHeight = isFilterOpen
    ? windowHeight - 395.5
    : windowHeight - 182;
  return (
    <Box>
      {viewMode === viewTypesEnum.TABLE ? (
        <DataGridProComponent
          rows={allOrders}
          columns={columns}
          loading={loading}
          getRowId={(row) => row.OrderNo}
          selectionModel={selectionModel}
          onSelectionModelChange={(oNo) => handleSelectedRow(oNo)}
          checkboxSelection
          rowsCount={allOrdersCount}
          paginationChangeMethod={getAllOrders}
          paginationMethodUrl={EnumChangeFilterModelApiUrls.GET_ALL_ORDERS.url}
          defaultRowsPerPage={
            EnumChangeFilterModelApiUrls.GET_ALL_ORDERS.length
          }
          height={calculatedHeight}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            overflowX: "auto",
            bgcolor: "#F8F8F8",
            height: calculatedGridHeight,
            border: greyBorder,
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
            padding: "8px",
          }}
        >
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <GridContainer spacing={2}>
              {allOrders.map((dt, index) => (
                <GridItem xs={12} sm={6} md={4} lg={3} key={index}>
                  <OrderCardComponent
                    onEyeClick={() =>
                      handleGetShipmentItemInfoByOrderNo(dt.OrderNo)
                    }
                    loading={!!orderIteminfoModal.loading[dt.OrderNo]}
                    orderData={
                      <>
                        <Box display="flex" justifyContent="space-between">
                          <Typography variant="body2">Order Number</Typography>
                          <Typography variant="body2">
                            {dt.OrderNo} <ClipboardIcon text={dt.OrderNo} />
                          </Typography>
                        </Box>
                        <Box>
                          <Box display="flex" justifyContent="space-between">
                            <Typography variant="body2">
                              Customer Name
                            </Typography>
                            <Typography variant="body2">
                              {dt.CustomerName}
                            </Typography>
                          </Box>
                        </Box>
                        <Box>
                          <Box display="flex" justifyContent="space-between">
                            <Typography variant="body2">Store Name</Typography>
                            <Typography variant="body2">
                              {dt.StoreName}
                            </Typography>
                          </Box>
                        </Box>
                        <Box>
                          <Box display="flex" justifyContent="space-between">
                            <Typography variant="body2">Order Type</Typography>
                            <Typography variant="body2">
                              {dt.OrderTypeName}
                            </Typography>
                          </Box>
                          <Box>
                            <Box>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Typography variant="body2">
                                  Assign Carrier
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: !dt?.CarrierId ? Colors.danger : "",
                                  }}
                                >
                                  {getCarrierText(dt?.OrderNo)}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box>
                          <Box display="flex" justifyContent="space-between">
                            <Typography variant="body2">Amount</Typography>
                            <Typography variant="body2">{dt.Amount}</Typography>
                          </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                          <Typography variant="body2">
                            Tracking Status
                          </Typography>
                          <Box
                            display="flex"
                            flexDirection={"column"}
                            alignItems="center"
                          >
                            <StatusBadge
                              title={dt.TrackingStatus}
                              color="#1E1E1E"
                              bgColor="#EAEAEA"
                              showBtn={
                                !dt?.IsClientCarrier && dt?.CarrierId != null
                              }
                              btnLoading={
                                refreshCarrierStatusLoading[dt.OrderId]
                              }
                              btnOnClick={() => handleRefresh([dt.OrderNo])}
                            />
                            {dt?.CarrierLastUpdateDateTime && (
                              <Box
                                sx={{ textAlign: "center", display: "inline" }}
                              >
                                <Typography fontSize="10px">
                                  {UtilityClass.convertUtcToLocalAndGetTime(
                                    dt?.CarrierLastUpdateDateTime
                                  )}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </>
                    }
                    cardActionsChildren={
                      <ActionButtonCustom
                        sx={{
                          ...styleSheet.integrationactivatedButton,
                          width: "100%",
                          height: "28px",
                          borderRadius: "4px",
                        }}
                        variant="contained"
                        loading={infoModal.loading?.[dt?.OrderNo] ?? false}
                        onClick={() =>
                          handleGetShipmentInfoByOrderNo(dt.OrderNo)
                        }
                        label={"Edit Order"}
                      />
                    }
                    status={{
                      label: dt.PaymentStatus,
                      color:
                        dt.PaymentStatus === "Unpaid"
                          ? Colors.danger
                          : Colors.succes,
                    }}
                  />
                </GridItem>
              ))}
            </GridContainer>
          )}
          <PaginationComponent
            name={"orders"}
            dataCount={allOrdersCount}
            paginationChangeMethod={getAllOrders}
            paginationMethodUrl={
              EnumChangeFilterModelApiUrls.GET_ALL_ORDERS.url
            }
            defaultRowsPerPage={
              EnumChangeFilterModelApiUrls.GET_ALL_ORDERS.length
            }
            setLoading={setAllOrderLoading}
            color="primary"
          />
        </Box>
      )}
      {infoModal.data?.result && (
        <OrderDetailModal
          data={infoModal?.data?.result}
          open={infoModal.open}
          onClose={() => setInfoModal((prev) => ({ ...prev, open: false }))}
          isSendNotification={false}
          isUpdateOrderBtn={true}
          printCarrier={infoModal?.data?.result?.order?.CarrierTrackingNo}
          modelType={EnumDetailsModalType.ORDER}
          setIsNoteAdded={setIsNoteAdded}
          isNoteAdded={isNoteAdded}
        />
      )}
      {orderIteminfoModal.data?.result && (
        <OrderItemDetailModal
          onClose={() =>
            setOrderIteminfoModal((prev) => ({ ...prev, open: false }))
          }
          data={orderIteminfoModal?.data?.result}
          open={orderIteminfoModal.open}
        />
      )}
    </Box>
  );
}
export default forwardRef(OrderList);
